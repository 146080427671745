import './src/assets/css/global.scss'

export const onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (!(`IntersectionObserver` in window)) {
    import(`intersection-observer`)
    console.log(`# IntersectionObserver is polyfilled!`)
  }

  if (typeof window !== 'undefined' && window.location.hostname === 'production.d38j3vm084y6h3.amplifyapp.com/') {
    const newPath = window.location.pathname + window.location.search + window.location.hash
    window.location.replace(`https://www.ayalalandpremier.com/${newPath}`)
  }
}
